var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard"},[_c('div',{staticClass:"dashboard__container"},[_c('div',{staticClass:"dashboard__container--header"},[_c('h1',[_vm._v("Contacts")]),_c('router-link',{staticClass:"color--text",attrs:{"to":{name: 'addcontact'}}},[_c('button',{staticClass:"btn btn__flat"},[_c('i',{staticClass:"fas fa-plus fa-2x"})])])],1),_c('div',{staticClass:"dashboard__container--body"},[(!_vm.contacts || _vm.contacts.length == 0)?_c('Loader'):_vm._e(),(_vm.contacts && _vm.contacts.length >= 1)?_c('div',{staticClass:"flex pt-3",staticStyle:{"width":"100%"}},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.contacts,"compactMode":"","styleClass":"vgt-table condensed","search-options":{
            enabled: true,
            placeholder: 'Search this table',
          },"pagination-options":{
            enabled: true,
            mode: 'records',
            perPage: 10,
          }},on:{"on-row-click":_vm.onRowClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'created')?_c('span',[(props.row.created)?_c('span',[_vm._v(_vm._s(_vm.formatDate(props.row.created)))]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,3952757218)})],1):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }